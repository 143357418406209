import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core'
import { IEmployee } from '../models/employee';
import { BehaviorSubject, EMPTY, Observable, Subject, catchError, combineLatest, concatMap, merge, of, scan, shareReplay, switchMap, tap, throwError, toArray } from 'rxjs';
import { environment } from "../../environments/environment";
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    private enforceApiUrl = environment.enforceApiUrl;
    private _httpClient = inject(HttpClient);
    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    private employee : IEmployee =  {
      id: '', employeeId: 0, firstName: '', lastName: '', role:'', avatar: '', phoneNumber: '',
      emailAddress: '', address: {id:0, placeId: '', streetAddress1: '', streetAddress2: '', city:'', state:'', zipCode: '', zipCodeExtension: '', location : {latitude:0, longitude:0}    }, 
      availabilities: [], isActive: true,
      organizationId: '', requiredSkills: [], hireDate: new Date()
  }
    public addedEmployee = signal<IEmployee>(this.employee);
    public employeeAdded = signal<boolean>(false);
    public updatedEmployee = signal<IEmployee>(this.employee);
    public employeeUpdated = signal<boolean>(false);
    public deletedEmployee = signal<IEmployee>(this.employee)
    public employeeDeleted = signal<boolean>(false);
    public employees = signal<IEmployee[]>([])
    public assignedEmployees = signal<IEmployee[]>([])

    employees$ = this._httpClient.get<IEmployee[]>(this.enforceApiUrl  + 'enforce/api/v1/' +  'employee' + '/organization/' + environment.organizationId)
    employeesActive$ = this._httpClient.get<IEmployee[]>(this.enforceApiUrl + 'enforce/api/v1/' + 'employee' + '/' + environment.organizationId + '/active');

    addEmployee(employee:IEmployee){      
      const newEmployee$ = this._httpClient
                 .post<IEmployee>(this.enforceApiUrl + 'enforce/api/v1/' + 'employee/' + 'organization/'+ environment.organizationId, employee, this.options);
      
      return newEmployee$;
    }

    updateEmployee(employee: IEmployee) {
        return this._httpClient.put<IEmployee>(this.enforceApiUrl + 'enforce/api/v1/' + 'employee/' +  employee.id + '/organization/' + employee.organizationId, employee, this.options)
    }
    deleteEmployee(employeeId: string){
      return this._httpClient.delete<boolean>(this.enforceApiUrl + 'enforce/api/v1/' +'employee/' + employeeId +  '/organization/' + environment.organizationId, this.options)
    }

    getByEmployeeId(employeeId: string) {
        return this._httpClient.get<IEmployee>(this.enforceApiUrl + 'enforce/api/v1/' + 'employee/' +  employeeId + '/organization/' + environment.organizationId)
    }
    addEmployees(employees:IEmployee[]){      
      const newEmployee$ = this._httpClient
                 .post<IEmployee[]>(this.enforceApiUrl + 'enforce/api/v1/' + 'employee/' + 'organization/'+ environment.organizationId + "/import", employees, this.options);
      
      return newEmployee$;
    }

    private handleError(err: HttpErrorResponse): Observable<never> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          errorMessage = `An error occurred: ${err.error.message}`;
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(() => errorMessage);
    }




}